import * as React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ top: 200 }}>
      <p>not found</p>
    </div>
  );
};

export default NotFoundPage;
